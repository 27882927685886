import React, { useEffect, useState } from "react";
import {
  SearchOutlined,
  UploadOutlined,
  QrcodeOutlined,
  IeOutlined,
} from "@ant-design/icons";
import { Table, Input, Button, Space, Tag, Popconfirm, Modal } from "antd";
import { del, getList, save } from "../../infrastructure/pdf_qr_menu_service";
import PdfFileForm from "./pdf_file_form";
import newPdfFile from "../../infrastructure/field_sets/new_pdf_file";
import PdfFileUpload from "./pdf_file_upload";
import QrCodeViewer from "./qr_code_viewer";

const PDFFileList = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sortedInfo, setSortedInfo] = useState({});
  const [searchText, setSearchText] = useState("");
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [open, setOpen] = useState(0);
  const [fields, setFields] = useState(newPdfFile);
  const [record, setRecord] = useState({});

  let [filteredData] = useState();

  const loadData = async () => {
    setLoading(true);

    getList("PdfFile").then((result) => {
      setLoading(false);
      filteredData = result.filter((value) => {
        return (
          value.modelName.toLowerCase().includes(searchText.toLowerCase()) ||
          value.description.toLowerCase().includes(searchText.toLowerCase())
        );
      });

      setData(filteredData);
    });
  };

  const handleInputChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleChange = (...sorter) => {
    const { order, field } = sorter[2];
    setSortedInfo({ columnKey: field, order });
  };

  const handleDelete = async (value) => {
    await del("PdfFile", value);
    await loadData();
  };
  const addNew = async () => {
    setFields(newPdfFile);
    setOpen(1);
  };

  const handleOk = async (values) => {
    if (values.modelName === undefined) {
      return;
    }

    setConfirmLoading(true);
    var newProduct = await save("PdfFile", values);
    await loadData();
    setConfirmLoading(false);

    setOpen(0);
    //save({ ...values }).then((result) => {
    //  setConfirmLoading(false);
    //  setOpen(0);
    //});
  };

  const handleCancel = (e) => {
    setOpen(0);
  };

  const edit = async (record) => {
    setFields([
      {
        name: ["id"],
        value: record.id,
      },
      {
        name: ["modelName"],
        value: record.modelName,
      },

      {
        name: ["description"],
        value: record.description,
      },
      {
        name: ["uuid"],
        value: record.uuid,
      },
      {
        name: ["url"],
        value: record.url,
      },
    ]);

    setOpen(1);
  };
  const uploadFile = async (rcd) => {
    setRecord(rcd);
    setOpen(2);
  };

  const showQr = async (rcd) => {
    setRecord(rcd);
    if (rcd.uuid == null) {
      return;
    }
    setOpen(3);
  };

  const showFabriqQr = async (rcd) => {
    setRecord(rcd);
    if (rcd.uuid == null) {
      return;
    }
    setOpen(4);
  };

  const columns = [
    {
      title: "R.No",
      dataIndex: "id",
      align: "center",
      sorter: (a, b) => a.Id > b.Id,
      sortOrder: sortedInfo.columnKey === "id" && sortedInfo.order,
    },
    {
      title: "Model Adı",
      dataIndex: "modelName",
      align: "center",
      sorter: (a, b) => a.modelName.localeCompare(b.modelName),
      sortOrder: sortedInfo.columnKey === "modelName" && sortedInfo.order,
    },
    {
      title: "Model Açıklama",
      dataIndex: "description",
      align: "center",
      sorter: (a, b) => a.description.localeCompare(b.description),
      sortOrder: sortedInfo.columnKey === "description" && sortedInfo.order,
    },
    {
      title: "Web Sitesi",
      dataIndex: "url",
      align: "center",
      sorter: (a, b) => a.url.localeCompare(b.url),
      sortOrder: sortedInfo.columnKey === "url" && sortedInfo.order,
    },
    {
      title: "UUID",
      dataIndex: "uuid",
      align: "center",
      sorter: (a, b) => a.uuid.localeCompare(b.uuid),
      sortOrder: sortedInfo.columnKey === "uuid" && sortedInfo.order,
    },
    {
      title: "İşlemler",
      dataIndex: "action",
      render: (_, record) => {
        return data.length >= 1 ? (
          <Space>
            <Popconfirm
              title="Bu kaydı silmek istediğinizden emin misiz?"
              onConfirm={() => handleDelete(record)}
            >
              <Button danger type="primary">
                Sil
              </Button>
            </Popconfirm>
            <Button onClick={() => edit(record)} type="primary">
              Güncelle
            </Button>

            <Button onClick={() => uploadFile(record)} type="primary">
              <UploadOutlined />
            </Button>

            <Button onClick={() => showQr(record)} type="primary">
              <QrcodeOutlined />
            </Button>

            <Button onClick={() => showFabriqQr(record)} type="primary">
              <IeOutlined />
            </Button>
          </Space>
        ) : null;
      },
    },
  ];

  const globalSearch = async (e) => {
    await loadData();
  };

  return (
    <div>
      <Modal
        title="Katalog Kartı"
        open={open == 1}
        footer={null}
        onCancel={() => {
          setOpen(0);
        }}
      >
        <PdfFileForm
          fields={fields}
          onCancel={handleCancel}
          onOK={handleOk}
          onChange={(newFields) => {
            setFields(newFields);
          }}
        />
      </Modal>

      <Modal
        title="Dosya Yükle"
        open={open == 2}
        footer={null}
        onCancel={() => {
          setOpen(0);
        }}
      >
        <PdfFileUpload
          record={record}
          onCancel={() => {
            setOpen(0);
          }}
        ></PdfFileUpload>
      </Modal>

      <Modal
        title="Qr Kod Görüntüleme"
        open={open == 3}
        typ
        footer={null}
        onCancel={() => {
          setOpen(0);
        }}
      >
        <QrCodeViewer
          record={record}
          type="0"
          onCancel={() => {
            setOpen(0);
          }}
        ></QrCodeViewer>
      </Modal>

      <Modal
        title="Url Qr Kod Görüntüleme"
        open={open == 4}
        footer={null}
        onCancel={() => {
          setOpen(0);
        }}
      >
        <QrCodeViewer
          record={record}
          type="1"
          onCancel={() => {
            setOpen(0);
          }}
        ></QrCodeViewer>
      </Modal>

      <Space>
        <Button onClick={addNew} type="primary">
          Yeni Katalog Kartı
        </Button>
        <Input
          style={{ width: 200, margin: 10 }}
          placeholder="Filtre"
          type="text"
          allowClear
          value={searchText}
          onChange={handleInputChange}
        />
        <Button onClick={globalSearch} type="primary" icon={<SearchOutlined />}>
          Listele
        </Button>
        <Tag color="orange">({data.length})</Tag>
      </Space>

      <br />
      <br />
      <Table
        columns={columns}
        dataSource={data}
        bordered
        loading={loading}
        onChange={handleChange}
        size="middle"
        rowKey="id"
      />
    </div>
  );
};

export default PDFFileList;
