import { useState } from "react";
import logo from "../../assets/images/logo.png";

import {
  HomeOutlined,
  HddOutlined,
  LoginOutlined,
  AndroidOutlined,
  CreditCardOutlined,
  BarChartOutlined,
  LogoutOutlined,
  UserOutlined,
} from "@ant-design/icons";

import { Image, Menu, Avatar, Space, Typography, Divider, Button } from "antd";

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}
const items = [
  getItem("Ana Sayfa", "1", <HomeOutlined />),
  getItem("Katalog Listesi", "2", <HddOutlined />),
];

const PDFMenu = ({ onClick, logedInUser, onLogout }) => {
  return (
    <div style={{ backgroundColor: "white" }}>
      <div style={{ textAlign: "center", marginTop: 16 }}>
        <Image src={logo} width={150} preview={false}></Image>
      </div>
      <Divider></Divider>
      <Space style={{ marginLeft: 5 }}>
        <Avatar shape="square" size={32} icon={<UserOutlined />} />
        <Typography.Title style={{ textAlign: "center", fontSize: 14 }}>
          {logedInUser}
        </Typography.Title>
      </Space>
      <Divider></Divider>
      <Menu
        onClick={onClick}
        style={{
          width: 250,
        }}
        defaultSelectedKeys={["1"]}
        items={items}
      />
      <Space style={{ marginLeft: 5, verticalAlign: "center" }}>
        <Button style={{ width: 120 }} onClick={onLogout}>
          Çıkış
          <LoginOutlined style={{ marginLeft: 10 }} />
        </Button>
      </Space>

      <Divider></Divider>
      <div style={{ textAlign: "center", fontSize: 10 }}>version : 1.0.1</div>
      <Divider></Divider>
    </div>
  );
};

export default PDFMenu;
