import { Space, Divider } from "antd";
import QRCode from "qrcode.react";
import React, { useState } from "react";

const QrCodeViewer = ({ record, type }) => {
  const [back, setBack] = useState("#FFFFFF");
  const [fore, setFore] = useState("#000000");
  const [size, setSize] = useState(256);

  console.log(record);
  console.log(type);

  const downloadQR = () => {
    const canvas = document.getElementById("123456");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    if (type == "0") {
      downloadLink.download = record.uuid + ".png";
    } else if (type == "1") {
      console.log("TYPE 1");
      downloadLink.download = record.uuid + "_url.png";
    }

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <div style={{ textAlign: "center", display: "flex" }}>
      <Space direction="vertical" align="center">
        <QRCode
          id={123456}
          value={
            type == "0"
              ? `https://datasheet.senolsogutma.com.tr/pdftoqr/api/PdfFile/getFile/${record.uuid}`
              : record.url
          }
          size={size}
        />
        <a onClick={downloadQR}> Dosyayı İndir </a>
      </Space>
    </div>
  );
};

export default QrCodeViewer;
