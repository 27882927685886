const newPdfFile = [
  {
    name: ["id"],
    value: 0,
  },
  {
    name: ["modelName"],
    value: "",
  },
  {
    name: ["description"],
    value: "",
  },
  {
    name: ["uuid"],
    value: "",
  },
  {
    name: ["url"],
    value: "",
  },
];

export default newPdfFile;
