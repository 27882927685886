import axios from "axios";

//const API_BASE_URL = "http://139.162.164.68:8080/horder";
const API_BASE_URL = "https://server.gurkanucar.com/pdftoqr/";
const instance = axios.create({
  baseURL: API_BASE_URL,
});

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    // Handle errors globally
    console.error("Axios error:", error);
    return Promise.reject(error);
  }
);

export const getList = async (modelName) => {
  const response = await instance.get(`/api/${modelName}`);

  return response.data;
};

export const save = async (modelName, model) => {
  const response = await instance.post(`/api/${modelName}`, model);

  return response.data;
};

export const saveFile = async (uuid, file) => {
  const formData = new FormData();
  formData.append("file", file);
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  console.log(uuid);
  const response = await instance.post(
    `/api/PdfFile/saveFile?uuid=${uuid}`,
    formData,
    config
  );

  console.log(response);

  return response.data;
};

export const del = async (modelName, model) => {
  const response = await instance.delete(`/api/${modelName}/${model.id}`);

  return response.data;
};
