import React, { useEffect, useState } from "react";
import { saveFile } from "../../infrastructure/pdf_qr_menu_service";
import { Button } from "antd";

const PdfFileUpload = ({ record, onCancel }) => {
  const handleImageChange = async (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        await saveFile(record.uuid, file);
        onCancel();
        event.target.value = null;
      };

      reader.readAsDataURL(file);
    }
  };

  return (
    <div>
      <input type="file" accept="*/*" onChange={handleImageChange} />
    </div>
  );
};

export default PdfFileUpload;
